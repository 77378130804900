.cardContainer {
  padding: 0;
}
.tbRowMenu {
  margin: 0;
}
.tbCellMenu {
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.25px;
  padding: 12.5px 16px;
}
.tbHeadCellMenu {
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.25px;
  padding-top: 31px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 18px;
}
.tbCell {
  padding: 17.5px 10px 19.5px 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0.25px;
}

.tbHeadCell {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.25px;
  padding-left: 0;
  padding-top: 44px;
  padding-bottom: 8px;
}

.rowContainer {
  width: calc(100% + 24px);
  margin-left: -24px;
}
.rowItem {
  padding-top: 18px;
  padding-left: 24px;
}

.rowContainerSearch {
  width: calc(100% + 32px);
  margin-left: -32px;
}
.rowItemSearch {
  padding-left: 32px;
  padding-top: 45px;
}
.rowItemSearchInput {
  padding-left: 32px;
  padding-top: 45px;
  .MuiOutlinedInput-input {
    padding: 9px 14px 10px 0;
  }
  &.pl-14 {
    .MuiOutlinedInput-input {
      padding-left: 14px;
    }
  }
}
.rowItemSearchBtn {
  padding-top: 45px;
}
.searchInputText {
  input {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.25px;
    padding: 0;
  }
}
.signitureFont {
  font-size: 18px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0.25px;
}
.MuiFormHelperText-root {
  margin-left: 0;
}

.autocompleteInput {
  .MuiAutocomplete-endAdornment {
    top: calc(50% - 18px);
  }
  .MuiAutocomplete-input {
    padding: 0px 4px 0px 6px !important;
    margin-top: 3px;
    margin-bottom: 3px;
    height: 21.09px;
  }
}

.selectInput {
  .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
    padding: 10px 14px !important;
  }
}

.textFieldInput {
  .MuiInputBase-input.MuiOutlinedInput-input {
    padding: 12px 14px !important;
    height: 21.09px;
  }
}

.labelBorder {
  .MuiInputLabel-shrink {
    font-size: 14px !important;
    line-height: 16.41px;
    transform: translate(14px, -9px) scale(1) !important;
    background-color: white;
    padding-right: 8px;
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 10px) scale(1);
  }
  .MuiOutlinedInput-input {
    padding: 10px 14px;
  }
}

.labelNoBorder {
  .MuiInputLabel-shrink {
    transform: translate(0, -1.5px) scale(1) !important;
  }
  .MuiInputLabel-outlined {
    transform: translate(0, -20px) scale(1);
  }
}

.date,
.time {
  .MuiOutlinedInput-root {
    padding: 0 9px 0 9px;
  }
  .MuiOutlinedInput-input {
    padding: 9px 4px 10px 6px !important;
  }
  .MuiSvgIcon-root {
    width: 24px;
    height: 24px;
    margin-right: 6px;
  }
  .MuiTouchRipple-root {
    width: 40px;
    height: 35px;
  }
  .employeeName {
    margin-top: 10px;
  }
  input {
    text-transform: uppercase;
  }
}

.defaultTab {
  background-color: gray;
  color: white;
  border-right: 0.5px solid white;
}

.activeTab {
  background-color: #1976d2;
  color: white !important;
  border-right: 0.5px solid white;
}

.tabs-container {
  margin-top: 45px;
  border: 1px solid #bdbdbd;
  min-height: 500px;

  button.MuiTab-root {
    background-color: gray;
    color: white;
    border-right: 0.5px solid white;

    &.Mui-selected {
      background-color: #1976d2;
      color: white !important;
      border-right: 0.5px solid white;
    }
  }

  .MuiTabs-scrollButtons.Mui-disabled {
    opacity: 0.4 !important;
  }
}

.search-bar {
  padding-top: 36px;
  padding-bottom: 18px;
}

.itemSearchInput {
  .MuiOutlinedInput-input {
    padding: 9px 14px 10px 0;
  }
  &.pl-14 {
    .MuiOutlinedInput-input {
      padding-left: 14px;
    }
  }
}

.donor-type-select {
  width: 200px;
}

.signCanvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

.signContainer {
  position: relative;
  width: 450px;
  height: 106px;
}

.helperTextError{
  color: #d32f2f;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  padding-bottom: 10px;
}
