.Sidebar {
  .formCategory {
    margin-top: -32px;
    margin-left: 16px;
  }
  .formCategoryLabel {
    font-family: Roboto;
    font-size: 16px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0.25px;
    text-align: left;
    padding-bottom: 10px;
    top: 14px;
  }
  .navItemContainer {
    margin-top: 4px;
    margin-bottom: 4px;
    margin-right: 8px;
    margin-left: -8px;
    padding: 8px;
    width: 240px;
    height: 43px;
    border-radius: 4px;
  }
  .navIcon {
    width: 24px;
    height: 24px;
    margin-top: 8px;
    margin-right: 8px;
    margin-bottom: 11px;
  }
  .leftSidebar{  
    background: linear-gradient(180deg, #01529C 4.69%, #73B0E2 95.83%);
    border: 'none'
  }
}
