.Menu {
  .titleContainer {
    padding-left: 9px;
    padding-top: 5px;
    padding-bottom: 16px;
    padding-right: 0;
    .MuiCardHeader-action {
      margin: 0;
    }
  }
  .searchInput {
    padding: 0px 8px;
    input {
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      letter-spacing: 0.44px;
      padding: 7px 14px 7px 0px;
    }
  }
  .cardContent {
    padding: 0;
  }
}
